.typed-cursor {
    opacity: 1;
}
.typed-cursor.typed-cursor--blink {
    animation: typedjsBlink 0.7s infinite;
    -webkit-animation: typedjsBlink 0.7s infinite;
    animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink {
    50% {
        opacity: 0;
    }
}
@-webkit-keyframes typedjsBlink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.form-control2 {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    background-clip: padding-box !important;
    background-color: #fff !important;
    border: 1px solid #f1f4f8 !important;
    border-radius: 0.375rem !important;
    box-shadow: 0 0 !important;
    color: #161c2d !important;
    display: block !important;
    font-size: 1.0625rem !important;
    font-weight: 400 !important;
    line-height: 1.6 !important;
    padding: 0.8125rem 1.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    width: 100% !important;
}

.btn:not([class*="btn-outline"]) {
    border-color: transparent !important;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
.w-100 {
    width: 100% !important;
}
.btn-primary2 {
    background-color: #6275e7 !important;
    border-color: #6275e7 !important;
    box-shadow: 0 0;
    color: #fff !important;
}
.btn2 {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    color: #161c2d;
    cursor: pointer;
    display: inline-block;
    font-size: 1.0625rem;
    font-weight: 600;
    line-height: 1.6;
    padding: 0.8125rem 1.25rem;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
}

.mb-6 {
    margin-bottom: 2rem !important;
}

.bg-gray-200 {
    background-color: #f1f4f8 !important;
}

.input2 {
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
    margin: 0em;
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}

.form-label {
    margin-bottom: 0.5rem;
}
.mb-6 {
    margin-bottom: 2rem !important;
}

.mt-6 {
    margin-top: 2rem !important;
}

.form-select2 {
    -moz-padding-start: calc(1.25rem - 3px);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 1 3.25 3.25L7.5 1' stroke='%23C6D3E6' stroke-width='1.083' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-position: right 1.25rem center;
    background-repeat: no-repeat;
    background-size: 1em 1em;
    border: 1px solid #f1f4f8;
    border-radius: 0.375rem;
    box-shadow: 0 0;
    color: #161c2d;
    display: block;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.6;
    padding: 0.8125rem 1.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
}

/* .form-check-input:checked {
    background-color: #6275e7;
    border-color: #6275e7;
} */
.form-check-input[type="checkbox"] {
    border-radius: 0.375rem;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5rem;
}
.form-check-input {
    -webkit-print-color-adjust: exact;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #d9e2ef;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: transparent;
    color-adjust: exact;
    height: 1em;
    margin-top: 0.3em;
    vertical-align: top;
    width: 1em;
}

[class^="container"] {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    position: relative;
    z-index: 1;
}
[class^="col"] {
    position: relative;
}
.py-8 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
}
.col-12-sign-in {
    flex: 0 0 auto;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
}
/* body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(22, 28, 45, 0);
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    margin: 0;
    text-align: var(--bs-body-text-align);
} */
.btn-warning2 {
    color: #fff;
    background-color: #fdc16a;
    border-color: #fdc16a;
}
.btn2-warning:hover {
    color: #fff;
    background-color: #fcb144;
    border-color: #fcac38;
}
.btn2-warning:focus,
.btn2-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 170, 96, 0.5);
}
.btn2-warning.disabled,
.btn2-warning:disabled {
    color: #fff;
    background-color: #fdc16a;
    border-color: #fdc16a;
}
.btn2-warning:not(:disabled):not(.disabled):active,
.btn2-warning:not(:disabled):not(.disabled).active,
.show > .btn2-warning.dropdown-toggle {
    color: #fff;
    background-color: #fcac38;
    border-color: #fca72b;
}
.btn2-warning:not(:disabled):not(.disabled):active:focus,
.btn2-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn2-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 170, 96, 0.5);
}
