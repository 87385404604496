body {
    margin: 0;
    /* zoom: 85%; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

.auth-inner {
    width: 450px;
    margin: auto;
    background: #f8fbfd;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
}

.auth-inner-signup {
    width: 600px;
    margin: auto;
    background: #f8fbfd;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
}

.auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
}

.upload-file {
    border: 5px dashed #e9ecef;
    cursor: pointer;
    border-radius: 6px;
    text-align: center;
    padding: 40px;
}

.upload-file i {
    width: 50px;
    height: 50px;
    display: inline-block;
    opacity: 0.7;
}

.upload- p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-90w {
    min-width: 70%;
}
.modal-60w {
    min-width: 60%;
}
.modal-20w {
    min-width: 20%;
}

.form-group-header {
    background: #f9f9fa
        linear-gradient(
            315deg,
            rgba(24, 96, 219, 0.05),
            rgba(150, 128, 255, 0.05)
        );
    padding: 20px;
    border-radius: 5px;
    border: 0.0625rem solid #cedbf0;
}

.addNewCatalogBloc {
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 14px;
    border-radius: 8px;
    height: 220px;
    border: 2px dashed #e8eaed;
}

.catalogBloc {
    height: 220px;
    border: 2px solid #e8eaed;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px 20px;
}

.catalogBloc p {
    margin: 0 0 5px 0;
    font-size: 18px;
    color: #494c51;
}

.catalogBloc h4 {
    color: #5f76e8;
    font-weight: bold;
}

.catalogBloc .statsCatalog {
    background-color: #f2f5f8 !important;
    border: 1px solid #c9cbcc;
    border-radius: 5px;
    padding: 10px;
    width: 90%;
    margin: 10px auto;
}

.catalogBloc .statsCatalog .statsRow {
    display: flex;
}

.catalogBloc .statsCatalog .bordered {
    border-left: 2px solid #c9cbcc;
}

.catalogBloc .statsCatalog .title {
    font-size: 17px;
    color: #494c51;
}

.catalogBloc .statsCatalog .number {
    font-size: 17px;
    color: #494c51;
}

.showOldBlocs {
    text-align: center;
    margin: 20px 0;
}

.hideOldBlocs {
    text-align: center;
    margin: 20px 0;
}

.showOldBlocs h4 {
    color: #5f76e8;
    font-weight: bolder;
}

.hideOldBlocs h4 {
    color: #5f76e8;
    font-weight: bolder;
}

.showOldBlocs h4:before {
    content: "\2193";
    padding-right: 5px;
}

.hideOldBlocs h4:before {
    content: "\2191";
    padding-right: 5px;
}

.addNewCatalogBloc h4 {
    color: #5f76e8;
    font-weight: bold;
}

.addNewCatalogBloc h4:before {
    content: "+";
    padding-right: 1px;
}

.addNewCatalogBloc p {
    margin: 5px !important;
}

.form-group-container {
    background: #f9f9fa
        linear-gradient(
            315deg,
            rgba(24, 96, 219, 0.05),
            rgba(150, 128, 255, 0.05)
        );
    padding: 20px;
    border-radius: 5px;
    border: 0.0625rem solid #cedbf0;
    border-top: 0 solid #e9ecef;
}

.form-group-button {
    background: #f9f9fa
        linear-gradient(
            315deg,
            rgba(24, 96, 219, 0.05),
            rgba(150, 128, 255, 0.05)
        );
    padding: 10px;
    border: 0.0625rem solid #425d8a;
}

.carousel .control-prev.control-arrow:before {
    border-right: 28px solid #2125297d !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 28px solid #2125297d !important;
    pointer-events: none;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    border-top: 28px solid transparent !important;
    border-bottom: 28px solid transparent !important;
}

.control-arrow:hover {
    background-color: transparent !important;
}

.carousel .control-dots {
    position: inherit !important;
}

.card-margin {
    margin: 0 30px;
    border: 6px solid #edf2f9;
    border-radius: 10px;
}
.card-margin-transition {
    margin: 0 30px;
    border: 6px solid #edf2f9;
    border-radius: 10px;
    overflow: hidden;
    animation-duration: 0.3s;
    animation-name: slidein;
}
@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}
.card-margin-remove {
    margin: 0 30px;
    border-radius: 10px;
    border: 6px solid #edafaf !important;
}

.card-margin-accepted {
    margin: 0 30px;
    border-radius: 10px;
    border: 6px solid #cbe5cb !important;
}

.carousel .control-dots .dot {
    display: none !important;
    background: #6a77e9 !important;
}

.carousel .carousel-status {
    text-shadow: #16171a !important;
    color: #050607 !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center;
    margin-top: 20px;
    font-size: 20px !important;
    /*display: none;*/
}

.form-radio-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.5rem;
    margin-right: 8px;
    size: 1000px;
    height: 20px;
    width: 25px;
}

fieldset.scheduler-border {
    border: 0.0625rem solid #cedbf0;
    padding: 0 1.4em 1.4em 1.4em;
    margin: 0 0 1.5em 0;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}
fieldset.choose-destination {
    border: 0.0625rem solid rgba(66, 70, 114, 0.1) !important;
    padding: 0 1.4em 0 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
}

.lds-ripple {
    position: fixed !important;
}

.group_button {
    margin-bottom: 10px;
}

.toreview-card .card-text {
    margin: 0 !important;
}
.flip-card-back {
    /* transform: rotateY(180deg); */
}

.btn-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 24px;
    border: 5px solid #dfdfdf;
}

.passBtn {
    font-size: 22px;
    float: right;
    position: absolute;
    right: 10px;
}

.slider .slide {
    opacity: 0.4;
    pointer-events: none;
}

.slider .selected {
    opacity: 1 !important;
    pointer-events: auto;
}

.slider .slide:not(.selected) .passBtn {
    display: none;
}

.disabledBtn {
    color: #cecece !important;
    cursor: not-allowed;
    background-color: #ffffff !important;
}

.css-1j9sz76-control {
    border-radius: 1px !important;
}

.previewFileUpload {
    border: 3px dashed rgb(238, 238, 238);
    border-radius: 10px;
    border-width: 6px;
    padding: 20px;
}

.flip-card-back {
}

.success-transition {
    transform: rotateY(90deg);
}

.modalTitle {
    font-weight: 700;
    font-size: 50px;
    color: #4c4c4c;
}

.modalP {
    font-size: 20px;
    margin-bottom: 5px;
}

.modalBtn {
    width: 30%;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    margin: 20px;
}

.payment-button {
    width: 30%;
    font-size: 20px;
    font-weight: 700;
}

.modal-40w {
    min-width: 40%;
}

.modal-50w {
    min-width: 50%;
}
.modal-40w {
    min-width: 40%;
}
.modal-30w {
    min-width: 30%;
}

.modal-50w .modal-content {
    border-radius: 15px;
}
.modal-40w .modal-content {
    border-radius: 15px;
}
.modal-90w .modal-content {
    border-radius: 15px;
}
.modal-60w .modal-content {
    border-radius: 15px;
}
.modal-20w .modal-content {
    border-radius: 15px;
}
.modal-40w .modal-content {
    border-radius: 15px;
}

.modal-30w .modal-content {
    border-radius: 15px;
}

.div-tooltip-catalog {
}

.div-tooltip-catalog-partner {
}

.div-tooltip-catalog .rdt_TableHeadRow > div:nth-child(4):hover::after {
    display: block;
    width: 100%;
}

.div-tooltip-catalog .rdt_TableHeadRow > div:nth-child(4) {
    /* margin: 20px; */
    position: relative;
}
.div-tooltip-catalog .rdt_TableHeadRow > div:nth-child(3):hover::after {
    display: block;
    width: 100%;
}

.div-tooltip-catalog .rdt_TableHeadRow > div:nth-child(3) {
    /* margin: 20px; */
    position: relative;
}
.div-tooltip-catalog .rdt_TableHeadRow > div:nth-child(4)::after {
    display: none;
    content: "Missing one or more required fields (Hotel Name, Hotel Address and/or Country)";
    position: absolute;
    left: 50%;
    /* bottom:-70px; */
    background: #1e1e1e;
    text-align: center;
    color: #fff;
    padding: 8px 4px;
    font-size: 12px;
    min-width: 300px;
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
    transform: translate(-50%, 100%);
}
.div-tooltip-catalog .rdt_TableHeadRow > div:nth-child(3)::after {
    display: none;
    content: "Includes the required fields";
    position: absolute;
    left: 50%;
    /* bottom:-70px; */
    background: #1e1e1e;
    text-align: center;
    color: #fff;
    padding: 8px 2px;
    font-size: 12px;
    min-width: 200px;
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
    transform: translate(-50%, 100%);
}

.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(7):hover::after {
    display: block;
    width: 100%;
}

.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(7) {
    position: relative;
}

.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(7)::after {
    display: none;
    content: "Missing one or more required fields (Hotel Name, Hotel Address and/or Country)";
    position: absolute;
    left: 50%;
    /* bottom:-70px; */
    background: #1e1e1e;
    text-align: center;
    color: #fff;
    padding: 8px 4px;
    font-size: 12px;
    min-width: 300px;
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
    transform: translate(-50%, 100%);
}
.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(5):hover::after {
    display: block;
    width: 100%;
}

.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(5) {
    position: relative;
}

.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(5)::after {
    display: none;
    content: "Properties not mapped";
    position: absolute;
    left: 50%;
    /* bottom:-70px; */
    background: #1e1e1e;
    text-align: center;
    color: #fff;
    padding: 8px 4px;
    font-size: 12px;
    min-width: 200px;
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
    transform: translate(-50%, 100%);
}
.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(6):hover::after {
    display: block;
    width: 100%;
}

.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(6) {
    position: relative;
}

.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(6)::after {
    display: none;
    content: "Includes the required fields";
    position: absolute;
    left: 50%;
    /* bottom:-70px; */
    background: #1e1e1e;
    text-align: center;
    color: #fff;
    padding: 8px 4px;
    font-size: 12px;
    min-width: 200px;
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
    transform: translate(-50%, 100%);
}
.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(3):hover::after {
    display: block;
    width: 100%;
}

.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(3) {
    position: relative;
}

.div-tooltip-catalog-partner .rdt_TableHeadRow > div:nth-child(3)::after {
    display: none;
    content: "Total hotels";
    position: absolute;
    left: 50%;
    /* bottom:-70px; */
    background: #1e1e1e;
    text-align: center;
    color: #fff;
    padding: 8px 4px;
    font-size: 12px;
    min-width: 100px;
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
    transform: translate(-50%, 100%);
}

.centred-div {
    margin-left: 20%;
    margin-right: 20%;
}

.fieldset {
    background-color: #fff;
    /*box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);*/
    border-radius: 4px;
    border: 1px solid #361cad;
    padding: 3px;
}

.tooltip-inner {
    background-color: #000000ba !important;
    border-radius: 8px !important;
}

.navbar-expand-lg .navbar-collapse {
    display: block !important;
    flex-basis: auto;
}
.form-control {
    box-shadow: none;
    border-radius: 4px;
}
.form-control:focus {
    box-shadow: none;
}
.search-form .form-control {
    height: 49px !important;
}
.search-form > * > * {
    margin-top: 20px;
}

.col-md-3 .col-md-2 {
    padding-right: 0px;
    padding-left: 9px;
}
.suggestion {
    cursor: pointer;
    border: 1px;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}
.suggestion:hover {
    background-color: grey;
}

.empty {
    font-size: 18px;
    opacity: 0.7;
}
.empty i {
    display: inline-block;
    transform: scale(0.6);
}
.empty svg {
    width: 100%;
    height: 100%;
    display: block;
}
.collapsible .content {
    padding: 2px;
    background-color: rgb(174 161 251 / 14%);
    border-radius: 0px;
}
.collapsible .header {
    background-color: #f8fbfd;
    padding: 15px;
    cursor: pointer;
    border-radius: 8px;
}

.navbar-expand-lg .navbar-collapse {
    display: block !important;
    flex-basis: auto;
}
.form-control {
    box-shadow: none;
    border-radius: 4px;
}
.form-control:focus {
    box-shadow: none;
}
.search-form .form-control {
    height: 49px !important;
}
.search-form > * > * {
    margin-top: 20px;
}

.col-md-3 .col-md-2 {
    padding-right: 0px;
    padding-left: 9px;
}
.suggestion {
    cursor: pointer;
    border: 1px;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}
.suggestion:hover {
    background-color: grey;
}

.empty {
    font-size: 18px;
    opacity: 0.7;
}
.empty i {
    display: inline-block;
    transform: scale(0.6);
}
.empty svg {
    width: 100%;
    height: 100%;
    display: block;
}

.zoomImage {
    transition: transform 0.2s; /* Animation */

    /* padding: 30px;
    width: 200px;
    height: 200px;
    margin: 0 auto; */
}

.zoomImage:hover {
    transform: scale(1.1);
}
.zoomImagett {
    transform: scale(1.2);
}

.glyphicon {
    content: "\2191";
}

.array-item-add .btn-add {
    margin: 20px 10px 0;
    padding: 5px 40px;
    background-color: #22ca80;
    border-color: #22ca80;
}
.array-item-add .btn-add .glyphicon:after {
    content: "Add new parameter";
}

.array-item .field-object fieldset {
    margin: 10px;
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 10px;
}

.array-item-remove {
    margin: 0 10px;
}
.array-item-remove .glyphicon:after {
    content: "Remove";
}
.zoomImage h3 {
    text-align: center;
}

.navbar-expand-lg .navbar-collapse {
    display: block !important;
    flex-basis: auto;
}
.form-control {
    box-shadow: none;
    border-radius: 4px;
}
.form-control:focus {
    box-shadow: none;
}
.search-form .form-control {
    height: 49px !important;
}
.search-form > * > * {
    margin-top: 20px;
}

.col-md-3 .col-md-2 {
    padding-right: 0px;
    padding-left: 9px;
}
.suggestion {
    cursor: pointer;
    border: 1px;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}
.suggestion:hover {
    background-color: grey;
}

.empty {
    font-size: 18px;
    opacity: 0.7;
}
.empty i {
    display: inline-block;
    transform: scale(0.6);
}
.empty svg {
    width: 100%;
    height: 100%;
    display: block;
}
.collapsible .content {
    padding: 2px;
    background-color: rgb(174 161 251 / 14%);
    border-radius: 0px;
}
.collapsible .header {
    background-color: #f8fbfd;
    padding: 15px;
    cursor: pointer;
    border-radius: 8px;
}

.cron_builder {
    max-width: 100% !important;
    border-radius: 11px !important;
}

.sub-title-info {
    margin-top: 5px;
    color: rgb(85, 85, 85);
    font-size: 18px;
}
.fbhklE {
    font-size: 16px !important;
}

.iAwKFK {
    font-size: 15px !important;
}
.addNewCatalogBloc p {
    font-size: 17px;
    color: #494c51;
}
.table-responsive span {
    font-size: 15px !important;
}

.cpjJTk {
    font-size: 16px !important;
}
.hGgXDQ {
    font-size: 17px;
    color: #494c51;
}

.sc-hKwDye {
    font-size: 15px !important;
}

.sc-egiyK {
    font-size: 16px !important;
}

.cron-builder-bg {
    background-color: #6076e8 !important;
}

.expanded-div {
    color: #6076e8;
    font-weight: bold;
}

.integration-entity-img-wrapper {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    background: #fff;
    box-shadow: 0 6px 20px -5px rgb(50 50 93 / 25%);
    transition: all 0.2s ease-in-out;
}
.destination-entity-img-wrapper {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    background: #fff;
    box-shadow: 0 6px 20px -5px rgb(50 50 93 / 25%);
    transition: all 0.2s ease-in-out;
    border-radius: 1rem;
    width: 19.25rem;
    height: 11.25rem;
}
.integration-entity-img-wrapper:hover {
    transform: scale(1.2);
    cursor: pointer;
}
.integration-entity-img-wrapper-connector {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    background: #fff;
    box-shadow: 0 6px 20px -5px rgb(50 50 93 / 25%);
    transition: all 0.2s ease-in-out;
}
.integration-entity-img-wrapper-partner {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    background: #fff;
    box-shadow: 0 6px 20px -5px rgb(50 50 93 / 25%);
    transition: all 0.2s ease-in-out;
}
.integration-entity-img-wrapper-connector:hover {
    transform: scale(1.2);
    cursor: pointer;
}
.integration-entity-name {
    color: #32325d;
    font-weight: 600;
}

.integration-entity {
    text-align: center;
    flex-shrink: 0;
    margin-bottom: 3rem;
}
.integration-entity-img-wrapper::before,
.integration-entity-img-wrapper::after {
    box-sizing: border-box;
}
.integration-entity-img-wrapper-connector::before,
.integration-entity-img-wrapper-connector::after {
    box-sizing: border-box;
}
.integration-entity-img-wrapper img {
    max-width: 3.75rem;
    max-height: 3.75rem;
}

.integration-group-container .entity-type-header:after {
    content: "";
    flex-grow: 1;
    margin-left: 1.25rem;
    height: 1px;
    border-bottom: solid 1px rgba(66, 70, 114, 0.1);
}
.search-input {
    width: 100%;
    border-radius: 0.75rem;
    padding: 1rem;
    left: 0;
    right: 0;
    transition: all 0.2s ease-in-out;
    max-width: 100%;
    border: 0;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 15%);
    margin-bottom: 17px;
    background-color: #fff;
}
.search-input input {
    flex-grow: 1;
    border: none;
    background: none;
}
.search-input .search-icon {
    margin-right: 0.75rem;
}

.search-input .search-icon {
    font-size: 1.25rem;
    height: 1.25rem;
    margin-right: 1.25rem;
}
.hevo-web-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.suggest-integration {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    padding: 2.5rem;
    margin: 2.5rem 0 5rem;
    background-color: #fafafa;
}
.justify-content-between {
    justify-content: space-between !important;
}
.suggest-integration .suggest-integration-illustration {
    margin: -2.5rem -2.5rem -2.5rem 0;
}
.pr-4,
.px-4 {
    padding-right: 1rem !important;
}
.mt-8,
.my-8 {
    margin-top: 2.5rem !important;
}
.tile_tqwLU .tileNode_JSIa3 .tileLogo_b0wF0 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    padding: 4px;
    border-radius: 16px;
    margin-bottom: 16px;
    margin-top: 8px;
    background-color: #66a2df26;
}

.destination-button-badge {
    color: #fff;
    border-radius: 100%;
    display: inline-block;
    padding: 2px 4px;
    font-size: 75%;
    text-align: center;
    vertical-align: baseline;
}
.destination-back-img {
    background-color: rgba(31, 104, 149, 0.1);
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    padding: 4px;
    margin-bottom: 16px;
    margin-top: 8px;
}
.card-shadow {
    box-shadow: 0 3px 9px 0 rgb(169 184 200 / 47%);
}

.list-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #eaecec;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.list-item:last-child {
    border-bottom: 0;
}
.list-item > * {
    width: 100%;
}
.list-item > *:first-child {
    max-width: 150px;
    max-height: 150px;
    margin-right: 20px;
}
.list-item > *:first-child > img {
    max-width: 100%;
}
.list-item > *:last-child {
    width: 30%;
    text-align: right;
}
.list-item figure {
    margin: 0;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
}
.list-item figure .status {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 20;
}
.list-item figure .status.green b {
    color: green;
}
.list-item figure .status.gray b {
    color: black;
}
.list-item .price {
    color: #000a13;
    font-size: 22px;
    font-weight: 300;
    background: #eaecec;
    border-radius: 3px;
    padding: 5px 10px;
}
.list-item p {
    color: black;
    font-size: 16px;
}
.list-item h3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 10px;
}
.styles_kPaginationListPage__2_6Ph {
    display: flex !important;
    justify-content: flex-end !important;
}
.styles_kPagination__1qVvD
    .styles_kPaginationListPage__2_6Ph
    .styles_kPaginationActive__A_vb- {
    color: #6876e8 !important;
    border-color: #6876e8 !important;
}

.profileImage {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #512da8;
    font-size: 35px;
    color: #fff;
    text-align: center;
    line-height: 150px;
    margin: 20px 0;
}

.react-pagination-div {
    justify-content: flex-end;
    display: flex;
    cursor: pointer;
}
ul.react-pagination-div {
    list-style: none;
    cursor: pointer;
}
/* .react-pagination-div-li {
    padding-left: 10px;
    padding-right: 10px;
} */
.card-destination {
    border: 1px solid rgb(232 233 236);
    border-radius: 0.75rem;
    cursor: pointer;
}
.card-without-hover {
    border: 1px solid rgb(232 233 236);
    border-radius: 0.75rem;
}
.card-partner-details {
    border: 1px solid rgb(232 233 236);
    border-radius: 0.75rem;
}
.card-destination:hover {
    border: 1px solid #6676e8;
    background-color: #6576e812;
    border-radius: 0.75rem;
}
.graph-details {
    width: 100%;
    display: flex;
    border-radius: 9px;
    overflow: hidden;
}
.graph-item-details {
    height: 15px;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}
.text-middle {
    overflow: hidden;
    color: #a5aaba;
    text-align: center;
}
.text-middle:before,
.text-middle:after {
    background-color: #a5aaba;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 30%;
}
.text-middle:before {
    right: 0.5em;
    margin-left: -50%;
}
.text-middle:after {
    left: 0.5em;
    margin-right: -50%;
}
.responsive-table-attribute li {
    border-radius: 3px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 25px;
}

.responsive-table-attribute {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}
.responsive-table-attribute .table-row-attribute {
    background-color: #fff;
    border: 2px solid #5f76e899;
    border-radius: 4px;
}

.text-warning-catalog {
    margin-left: 3px;
    font-style: italic;
}

.requiredFileProperties {
    background-color: #f2f5f8 !important;
    border: 1px solid #c9cbcc;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin: 10px auto;
}

.requiredFileProperties .statsRow {
    display: flex;
    align-items: center;
}

.requiredFileProperties .bordered {
    border-left: 2px solid #c9cbcc;
}

.requiredFileProperties .title {
    font-size: 17px;
    color: #494c51;
}

.attribute .table th {
    padding: 0.25rem 1rem 0.25rem 1rem;
    vertical-align: top;
    border: 1px solid #e8eaec;
    text-align: center;
}
.attribute .table td {
    padding: 0.25rem 1rem 0.25rem 1rem;
    vertical-align: top;
    border: 1px solid #e8eaec;
    text-align: center;
}

.card-text-mapping-details {
    margin-top: 0px;
    color: #808698;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.3px;
    position: relative;
    bottom: 0;
    padding-left: 0px;
    padding-right: 0px;
}
/* .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
} */

/* .input-box {
    position: relative;
    height: 50px;
}
input.real-input,
.fake-input {
    width: 500px;
    height: 30px;
    font-size: 20px;
    border: solid 1px #000;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
}
input.real-input {
    opacity: 0;
    background: transparent;
} */

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #7075e9 !important;
    color: #fff;
}

.react-datepicker__input-container input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccd3de;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #7075e9 !important;
    color: #fff;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0px 0.25rem 0.25rem 0px;
}

.rdrDefinedRangesWrapper {
    display: none;
}

.partner-mapped-rate .css-g1d714-ValueContainer {
    max-height: 70px;
    overflow: auto;
}

.chart-title {
    margin-left: 23px;
    margin-top: 20px;
    font-weight: 500;
}
.home-img-container {
    position: relative;
    text-align: center;
    color: white;
}

.home-img-text-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* color: black;
    font-size: 20px;
    font-weight: 500; */
    background-color: #f0f0f0;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    opacity: 66%;
    border-radius: 11px;
}
.home-img-text-container-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f0f0f0;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 80%;
    opacity: 66%;
    border-radius: 11px;
}

.home-img-text-container-centered span {
    color: black;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
}

.home .card {
    border-radius: 1.25rem;
}

.home .card-home-icon-complete {
    background: rgb(34 179 115);
    background-color: rgb(34 179 115);
    opacity: 78%;
}
.home .card-home {
    margin-left: 23px;
    margin-top: -20px;
}

.home .complete-icon {
    color: white;
    font-size: 25px;
    margin-left: 7px;
}

.home .card-body-home {
    padding: 15px 25px;
    border-radius: 1.25rem 1.25rem 0 0;
    background-color: white;
}

.home .card-paragraph {
    max-height: 48px;
    font-size: 18px;
    font-weight: 400;
}

.home .text-link-button {
    text-decoration: underline;
    color: #5f76e8;
    background-color: transparent;
    font-size: 18px;
    font-weight: 400;
    padding: 0px;
}

.home .button-step-complete {
    background-color: #60c49170;
    color: rgb(33, 153, 95);
    border: 1px solid #60c49170;
    border-radius: 1.25rem;
    padding: 0.375rem 0.75rem;
}

.mr-6 {
    margin-right: 6px;
}
.home .card-info-footer {
    background-color: white;
    border-radius: 0 0 1.25rem 1.25rem;
}

.home .card-info-complete {
    border-radius: 1.25rem;
}
.home .card-info {
    border-radius: 1.25rem;
    /* border: 1px solid #7075e938; */
}

.round-img {
    border-radius: 5rem;
}

.img-title-unmapped-count {
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 52px;
    padding-bottom: 12px;
    color: #212529;
    font-weight: 500;
    font-size: 18px;
}

.partner-report .card {
    border: 1px solid rgb(232 233 236);
    border-radius: 0.75rem;
}

.preview-column {
    color: rgb(146 145 145);
}

/* .react-pagination-div-li {
    padding-left: 10px;
    padding-right: 10px;
} */

.pagination {
    justify-content: center;
}

ul {
    list-style: none;
    padding: 0;
}

ul.pagination li {
    display: inline-block;
    width: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    font-size: 25px;
}

ul.pagination li a {
    text-decoration: none;
    color: #337ab7;
    font-size: 20px;
}

ul.pagination li.active a {
    color: white;
}
ul.pagination li.active {
    background-color: #337ab7;
}

ul.pagination li a:hover,
ul.pagination li a.active {
    color: blue;
}

.page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
}

.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.header .dropdown-menu {
    border-radius: 14px;
    margin-top: 6px;
}

.header .message-item:hover {
    background-color: #4f546724;
}

.btn-info {
    border-radius: 8px;
}
.btn-danger {
    border-radius: 8px;
}
.btn-success {
    border-radius: 8px;
}
.btn-warning {
    border-radius: 8px;
}
.btn-dark {
    border-radius: 8px;
}
.btn-light {
    border-radius: 8px;
}
.btn-primary {
    border-radius: 8px;
}

button.btn.btn-danger.array-item-remove {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.cont-sign-up {
    display: flex;
    flex-direction: column;
    height: 100%; /* Set a height for the container */
}

.top-sign-up {
    flex-grow: 0;
    flex-shrink: 0;
}

.bottom-sign-up {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto; /* Add vertical scrollbar when content overflows */
    margin-top: 10px;
    /* top: calc(
        100% + 50px
    ); */
    /* Position the bottom div below the top div with 10px space */
}
